import React, { useEffect, useState } from 'react';
import Loader from '../global/loader';
import { Datepicker } from "@meinefinsternis/react-horizontal-date-picker";
import swal from 'sweetalert2';
import BookingModal from './bookingModal';
import { getTeeTimeBook, getTeeTimeInit, blockTeeTime, getBookingSheet } from '../api/teeTimeAPI';
import TeeCard from './teeCard';
import TeeSheetModal from './teeSheetModal';
import ModalCaptcha from './modalCaptcha';

const morningString = 'Morning', afternoonString = 'Afternoon';

const BookingSheet = (props) => {
    
    /**@global */ 
    var publishData;
    let IntervalId;
    
    const [loading,             setLoading]             = useState(false);
    const [selectedDate,        setSelectedDate]        = useState();   
    const [teeTimeBook,         setTeeTimeBook]         = useState([]);
    const [shotgunBook,         setShotgunBook]         = useState([]);
    const [type,                setType]                = useState('');
    const [holiday,             setHoliday]             = useState();
    const [BookingUsers,        setBookingUsers]        = useState([]);
    const [bookingModal,        setBookingModal]        = useState(null);

    const [showTeeSheet,        setShowTeeSheet]        = useState();
    
    const [refresh,             setRefresh]             = useState(false);
    
    const [pageData,            setPageData]            = useState();
    const [serverTime,          setServerTime]          = useState();
    const [localTime,           setLocalTime]           = useState(moment().valueOf());
    const [teeTimeStatus,       setTeeTimeStatus]       = useState('unpublish');

    const [isMorningBlocked,    setIsMorningBlocked]    = useState(false);
    const [isAfternoonBlocked,  setIsAfternoonBlocked]  = useState(false);
    const [captcha,             setCaptcha]             = useState(false);
    const [bookDetails,         setBookDetails]         = useState({});

    useEffect(()=> { 
        fetchTeeTimeInit()
                
        setTimeout(()=> {  //scroll to last date           
            const element = document.getElementsByClassName('dr');         
            if(element.length > 0) element[0].scrollLeft += 200;            
        }, 230)  

        //Refetch teetimeinit on window resume
        document.addEventListener("visibilitychange", (f) => {
            clearInterval(IntervalId);
            if(document.visibilityState == "visible") fetchTeeTimeInit();            
        });

        return () => clearInterval(IntervalId); //Cleanup effect    
    }, []);
    
    useEffect(()=> { 
        if(selectedDate) fetchTeeTimeBook(selectedDate) 
    }, [selectedDate, refresh]);   
   
    
    //Listen to events from server -->
    useEffect(()=> { 
        if(!!props?.socketMaster){
            props.socketMaster.on('refresh-teesheet', (res) => {
                const {data} = res;                               
                if(data){
                    let isDateSame = true;
                                        
                    setSelectedDate(d => {
                        isDateSame = moment(moment(d).format('YYYY-MM-DD')).isSame(data.BookDate);                           
                        return d
                    })

                    if(!isDateSame) return;
                  
                    if(data.Type === 'Shotgun')
                        setShotgunBook(data.ShotgunBook);
                    else
                        setTeeTimeBook(data.TeeTimeBook);
                         
                    //Block /UnBlock User Clicks    
                    if(data.Action == 'Block'){ 
                        if(data.Session === morningString) setIsMorningBlocked(true);
                        if(data.Session === afternoonString) setIsAfternoonBlocked(true);                                                                                         
                    }else if(data.Action == 'Release'){
                        if(data.Session === morningString) setIsMorningBlocked(false);
                        if(data.Session === afternoonString) setIsAfternoonBlocked(false);                       
                    }           
                                                
                    // setBookingUsers(data.BookingUsers);
                }
            });
        }       
    },[props]);   
   
    const fetchTeeTimeInit = async()=> {     
        try {
            const { TeeTimeValidation, ServerDate } = await getTeeTimeInit();
            publishData = TeeTimeValidation;
                   
            initTimer(ServerDate);
            setPageData({TeeTimeValidation, ServerDate});                       
            setSelectedDate(moment(ServerDate).add( TeeTimeValidation.AdvanceBookingDays, 'd').toDate());                                                             
        } catch (err) {            
            swal.fire({ icon: "error",titleText: "Error!",text: err.message })
            return;             
        }
    }

    const fetchBookingSheet = async(params) => {
        let dateToFetch = params; 
                                                
        /**@listens - selectedDate < serverDate -> sends serverDate || selected Date */
        if((pageData?.ServerDate) && moment(dateToFetch).isBefore(pageData.ServerDate))
            dateToFetch = pageData?.ServerDate;
                    
        const response = await getBookingSheet(moment(dateToFetch).format('YYYY-MM-DD'));
        setBookingUsers(response.BookingUsers)
        setShowTeeSheet(true)
    };

    const fetchTeeTimeBook = async(params)=> {
        try {
            setLoading(true);          
            let dateToFetch = params; 
                                                
            /**@listens - selectedDate < serverDate -> sends serverDate || selected Date */
            if((pageData?.ServerDate) && moment(dateToFetch).isBefore(pageData.ServerDate))
                dateToFetch = pageData?.ServerDate;
                        
            const response = await getTeeTimeBook(moment(dateToFetch).format('YYYY-MM-DD'));
         
            setTeeTimeStatus(response.Status);
            setType(response.Type);
            setHoliday(response.Holiday);
             
            //Block As per session ->
            setIsMorningBlocked(response?.UserBookingMorning) 
            setIsAfternoonBlocked(response?.UserBookingAfternoon);          
         
            if(response.Status === 'publish'){                
                // setBookingUsers(response.BookingUsers)
                if(response.Type === 'Shotgun'){
                    setShotgunBook(response.ShotgunBook)
                }else{
                    setTeeTimeBook([...response.TeeTimeBook])
                }
            }else if( response.Status === 'closed'){
                setHoliday(response.LongMessage);
            }
            
            setLoading(false);
        } catch (err) {
            setLoading(false);
            swal.fire({icon: "error",titleText: "Error!",text: err.message});
            return;  
        }
    }
   
    const initTimer = (time)=> {       
        if(time){          
            setServerTime(moment(time));

            //setInterval for servertime -->
                IntervalId = setInterval(()=> {                
                let LocalTimeDiff = 0;

                setLocalTime( l => {
                    const newLocalTime = moment().valueOf();
                    LocalTimeDiff      = moment(newLocalTime).diff(moment(l));                  
                    return newLocalTime;
                })
                
                // if serverTime falls behind - refetch serverTime
                if(LocalTimeDiff > 3000){  
                    clearInterval(IntervalId);                 
                    fetchTeeTimeInit();
                }else{
                    let data;
                    
                    setServerTime(t => {                                   
                        const unixTS      = moment(t, "DD-MM-YYYY hh:mm:ss A").add(1, 'second').valueOf();
                        const displayTime = moment(unixTS).format('DD-MM-YYYY hh:mm:ss A');
                        data = { unixTS, serverDate : moment(t, "DD-MM-YYYY hh:mm:ss A")};

                        return displayTime;                                    
                    }); 
                    
                    if(data){                        
                        const mt = (moment(`${moment(data.serverDate).format("MM/DD/YYYY")} ${publishData.MorningTime}`, 'MM/DD/YYYY H:mm A'));
                        const at = (moment(`${moment(data.serverDate).format("MM/DD/YYYY")} ${publishData.AfternoonTime}`, 'MM/DD/YYYY H:mm A'));    
                                                                                           
                        if(data && (
                            moment(moment(data.unixTS).format('DD-MM-YYYY hh:mm:ss A'), 'DD-MM-YYYY hh:mm:ss A').isSame(mt) ||
                            moment(moment(data.unixTS).format('DD-MM-YYYY hh:mm:ss A'), 'DD-MM-YYYY hh:mm:ss A').isSame(at))
                        ){   
                            setSelectedDate(d => {
                                fetchTeeTimeBook(d)

                                return d;
                            });                                               
                        }                                                                                                                
                    }else{
                        clearInterval(IntervalId);      
                    }
                }                              
            }, 1000);
        }
    }

    const onBookSelect = async(book, captcha = false)=> {
        try {
            //disable if book time is passed-              
            const isAfter = moment(serverTime, 'DD-MM-YYYY hh:mm:ss A').isAfter( moment(`${moment(selectedDate).format("DD-MM-YYYY")} ${book.Slot}`, 'DD-MM-YYYY hh:mm A'));
            if(isAfter) return;
                    
            if(checkBookingStatus(book)) { //checks if user is blocked for this session.
                swal.fire({ icon: 'warning', timer: 2000, titleText:'Your booking exist for this session!', showConfirmButton: false})
                return;                       
            }
            
            if(!!book.ID || !!book.Status)  return;  //checks if booked by other user.                  

            book.BookDate  = moment(selectedDate).format("YYYY-MM-DD");
            const response = await blockTeeTime({...book, Captcha: captcha});                        
            book.ID        = response.BookID;
            setBookingModal(book);                        
        } catch (err) {         
            if(err.message == 'Error: Open Captcha')  {
                setBookDetails(book);
                setCaptcha(true)
            }else{
                swal.fire({icon: "error",titleText: "Error!",text: err.message});            
            }
        }       
    }
   
    const dateChange     = (value)=> setSelectedDate(value[0]);
    const refetchTeeTime = ()=> setRefresh((r) => !r);

    const checkBookingStatus = (book) => {       
        let isInValid = false;

        //disable if book time is passed-              
        const isAfter = moment(serverTime, 'DD-MM-YYYY hh:mm:ss A').isAfter( moment(`${moment(selectedDate).format("DD-MM-YYYY")} ${book.Slot}`, 'DD-MM-YYYY hh:mm A'));
        
        if(isAfter) isInValid = true;  //Combine With OR        
        if((book.Session == morningString) && isMorningBlocked)     isInValid = true;            
        if((book.Session == afternoonString) && isAfternoonBlocked) isInValid = true;

        return isInValid;
    }      
        
    return (  
        <>
            <div className="row sticky-format padding-0">
                <div className='col-xs-12'>
                                        
                    {(!!selectedDate) &&(
                        <div className='row'>
                            <div id="datepicker-containter" className='col-xs-12'>                               
                                <Datepicker                                   
                                    startDate   = {!!pageData? moment(pageData.ServerDate).toDate(): moment().toDate()}                                                
                                    startValue  = {selectedDate}
                                    endValue    = {selectedDate}                             
                                    endDate     = {!!pageData? moment(pageData.ServerDate).add( pageData.TeeTimeValidation.AdvanceBookingDays || 1, 'd').toDate(): moment().add(1, 'd').toDate()}                              
                                    onChange    = {dateChange}                              
                                />
                            </div>
                        </div>
                    )}
                    
                    <div className="row margin-t20">
                        <div className="col-xs-12 text-center">
                            <h6 id="txtServerTime" className="margin-0"> { serverTime && (moment(serverTime, "DD-MM-YYYY hh:mm:ss A").format('h:mm:ss A'))} </h6>
                        </div>
                    </div>

                    {/* Info */}
                    {(!!holiday) && (
                        <div id="pnlHoliday" className="row margin-tb5">
                            <div className="col-xs-12 text-center">
                                <h6 className="margin-tb0" style={{color: '#d15b47'}}><span id="lblHoliday"> { holiday.Name } </span></h6>
                            </div>
                        </div>
                    )}

                    <div className="row margin-tb5">
                        {!!type && (
                            <div className="col-xs-12 text-center">
                                <h6 className="margin-tb0 padding-tb5"><b id="lblStartType"> {(type == "Slot") ? 'Staggered Start' : 'Shotgun Start'} </b></h6>                         
                            </div>
                        )}
                        <div className='col-xs-6'>
                            <h6 className="text-muted text-center text-uppercase sticky-format dotLine"><b>1st Tee</b></h6>
                        </div>
                        <div className='col-xs-6'>
                            <h6 className="text-muted text-center text-uppercase sticky-format dotLine"><b>4th Tee</b></h6>
                        </div>
                    </div>
                    
                </div>    
            </div>
            
            {/* Listing  */}
            <div className="row padding-5">
                <div className='container'>
                    <div className="col-xs-12">                
                        {loading ? <Loader/> : (<>
                            {(teeTimeStatus === 'publish')?(                           
                                <div id="divTeeTime" className="row">
                                    <div className='col-xs-5'>
                                        <div className="row">
                                            {teeTimeBook.filter(f => f.TeeBox == 1).map((m, i) => (
                                                <TeeCard
                                                    key = {i}
                                                    book = {m}
                                                    onBookSelect = {(m) => onBookSelect(m)}
                                                    isBookingAllowed = { (checkBookingStatus(m))}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-xs-2"></div>
                                    <div className='col-xs-5'>
                                        <div className="row">
                                            {teeTimeBook.filter(f => f.TeeBox == 4).map((m, i) => (
                                                <TeeCard
                                                    key = {i}
                                                    book = {m}
                                                    onBookSelect = {(m) => onBookSelect(m)}
                                                    isBookingAllowed = { (checkBookingStatus(m))}
                                                />
                                            ))}
                                        </div>      
                                    </div>
                                </div>
                            ):(<>                            
                                <div className="panel panel-default panel-club fadeInUp">
                                    <div className="row wow fadeInUp animated animated">
                                        <div className="col-xs-12">
                                            <h4 className="text-center text-uppercase text-muted margin-tb50">
                                                {teeTimeStatus === 'closed' ? 'Course Closed': 'Tee Time Not Published' }
                                            </h4>
                                        </div>
                                    </div>
                                </div>    
                            </>)}
                        </>)}
                    </div>            
                </div>
            </div>
            
            {(teeTimeStatus === 'publish') && (
                <div className="row margin-t10">
                    <div className="col text-center">
                        <button id="btnBookSheet" type="button" className="btn btn-sm btn-success"
                            onClick={() => fetchBookingSheet(selectedDate)}
                        > Booking Sheet </button>
                    </div>
                </div>
            )}                                

            {!!bookingModal && (
                <BookingModal
                    show                = {!!bookingModal }
                    action              = {'a'}
                    onDismissModal      = {()=> setBookingModal(null) }
                    teeTimeBookData     = {bookingModal}
                    SelectedDate        = {moment(selectedDate).format('YYYY-MM-DD')}
                    refetchTeeTime      ={()=> refetchTeeTime()}
                />
            )}
           
           {!!showTeeSheet && (
                <TeeSheetModal
                    show           = {showTeeSheet}
                    teeSheet       = {(type == 'Shotgun')? shotgunBook : teeTimeBook}
                    bookingUsers   = {BookingUsers}
                    selectedDate   = {moment(selectedDate).format('DD-MM-YYYY')}
                    onDismissModal = {()=> setShowTeeSheet(null)}                                 
                />
            )}
            {!!captcha && (
                <ModalCaptcha
                    show           = {captcha}
                    onDismissModal = {()=> setCaptcha(false)}         
                    bookDetails    = {bookDetails}       
                    onBookSelect   = {onBookSelect}                 
                />
            )}
        </>
    );
};

export default BookingSheet;
