import { axiosGet, axiosPost } from "./axiosFunctions";
import { getAuthState } from "../../assets/scripts/login-util";
import {secretKey} from "../../env.json";
import { Encryptor } from "../../components/global/encryptor";

const { generateHmac } = Encryptor(secretKey);

const getTeeTimeBookUrl                              = '/api/teetime/getTeeTimeBook';
const getBookingSheetUrl                             = '/api/teetime/getBookingSheet';
const getAllActiveMembersUrl                         = '/api/users/getAllActiveMembers';
const blockTeeTimeUrl                                = '/api/teetime/blockTeeTime';
const bookTeeTimeUrl                                 = '/api/teetime/bookTeeTime';
const updateTeeTimeUrl                               = '/api/teetime/updateTeeTimeBook';
const deleteTeeTimeBookUrl                           = '/api/teetime/deleteTeeTimeBook';
const releasePendingTeeTimeUrl                       = '/api/teetime/releasePendingTeeTime';

const getUsersGuestUrl                               = '/api/users/getUsersGuest';
const postUsersGuestUrl                              = '/api/users/postUsersGuest';
const deleteUsersGuestUrl                            = '/api/users/deleteUsersGuest';

const getTeeTimeBookMemberListUrl                    = '/api/teetime/getTeeTimeBookMemberList';
const postFavouriteUserUrl                           = '/api/users/postFavouriteUser';

const getTeeTimeInitUrl                              = '/api/teetime/teeTimeInit';
const getMyTeeTimeBookingUrl                         = '/api/teetime/getMyTeeTimeBooking';
const getTeeTimeBookUserUrl                          = '/api/teetime/getTeeTimeBookUser';


export const getTeeTimeInit = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getTeeTimeInitUrl, { headers: { auth_token: `bearer ${token}` }} );
    return response;
}

export const getTeeTimeBook = async(date) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getTeeTimeBookUrl, { headers: { auth_token: `bearer ${token}` }, params: {date}} );
    return response;
}

export const getBookingSheet = async(date) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getBookingSheetUrl, { headers: { auth_token: `bearer ${token}` }, params: {date}} );
    return response;
}

export const getAllActiveMembers = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getAllActiveMembersUrl, { headers: { auth_token: `bearer ${token}` }} );
    return response;
}

export const blockTeeTime = async (book) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    const encryptedData = generateHmac(book);

    const response = await axiosPost(
        blockTeeTimeUrl,
        { book },
        { headers: { auth_token: `bearer ${token}`, Authorization:  encryptedData}} 
    );
    return response;
};

export const bookTeeTime = async (users, comment) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosPost(
        bookTeeTimeUrl,
        { users, comment },
        { headers: { auth_token: `bearer ${token}` }} 
    );
    return response;
};

export const updateTeeTime = async(book, users) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosPost(
        updateTeeTimeUrl,
        { book, users },
        { headers: { auth_token: `bearer ${token}` }} 
    );
    return response;
}

export const deleteTeeTimeBook = async(id, comment) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( deleteTeeTimeBookUrl, { headers: { auth_token: `bearer ${token}` }, params: { id, comment } } );
    return response;
}

export const releasePendingTeeTime = async (ID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet(   
        releasePendingTeeTimeUrl,        
        { headers: { auth_token: `bearer ${token}`},  params: { ID }} 
    );
    return response;
};

export const getTeeTimeBookMemberList = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getTeeTimeBookMemberListUrl, { headers: { auth_token: `bearer ${token}` }} );
    return response;
}

export const getUsersGuest = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getUsersGuestUrl, { headers: { auth_token: `bearer ${token}` }} );
    return response;
}

export const postUsersGuest = async (guest) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosPost(
        postUsersGuestUrl,
        { guest },
        { headers: { auth_token: `bearer ${token}` }} 
    );
    return response;
};

export const deleteUsersGuest = async(id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( deleteUsersGuestUrl, { headers: { auth_token: `bearer ${token}` }, params:{ id }} );
    return response;
}

export const postFavouriteUser = async (favouriteUserID, type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet(   
        postFavouriteUserUrl,        
        { headers: { auth_token: `bearer ${token}`},  params: { favouriteUserID, type }} 
    );
    return response;
};

export const getMyTeeTimeBooking = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getMyTeeTimeBookingUrl, { headers: { auth_token: `bearer ${token}` }} );
    return response;
}

export const getTeeTimeBookUsers = async(teeTimeBookId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet( getTeeTimeBookUserUrl, { headers: { auth_token: `bearer ${token}` }, params:{ teeTimeBookId }} );
    return response;
}