import FormFooter from "./formFooter";
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import * as helper from "../global/helper";

const Reference = (props) => {

    const{reference1Details, updateReference1Details, reference2Details, updateReference2Details, count} = props;

    return(
        <>
            <div className="panel panel-paper">
                <div className="panel-body form-onboarding"  style={{ minHeight: '140vh' }}>
                    <div className="panel-heading text-center" style={{marginBottom: '20px'}}>
                        {/* Logo and club name */}
                        <img className="img-responsive img-login margin-b10" src={bannerLogo} alt="" style={{height: '80px'}}/>
                        <h3 className="panel-title mb-0 padding-t0"><strong>THE GAEKWAD BARODA GOLF CLUB, MEMBERSHIP AGREEMENT</strong></h3>
                    </div>
                    <fieldset className="primary">
                        <legend > PLEASE MENTION NAMES OF TWO REFERENCES </legend>
                        <ReferenceForm 
                            referenceDetails={reference1Details} 
                            updateReferenceDetails={updateReference1Details}
                        />
                        <ReferenceForm 
                            referenceDetails={reference2Details} 
                            updateReferenceDetails={updateReference2Details}
                        />
                    </fieldset>
                </div>
                <FormFooter num={count}/>
            </div>
        </>
    )
};

export default Reference;
 
const ReferenceForm = (props) => {

    const{referenceDetails, updateReferenceDetails} = props;

    return(
        <>
            <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '20px' }}>
                <h5 className="text-center">REFERENCE </h5>
                <div className="row padding-lr100 padding-t20">
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="Ref1Name"> Name</label>
                            <input type="text" className="form-control" id="Ref1Name" value={referenceDetails.Name || ''} onChange={(event) => updateReferenceDetails('Name', helper.camelCase(event.target.value))}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="Ref1Email">Email</label>
                            <input type="email" className="form-control" id="Ref1Email" name="Ref1Email" value={referenceDetails.Email || ''} onChange={(event) => updateReferenceDetails('Email', event.target.value)}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="Ref1Mobile">Mobile</label>
                            <input type="text" className="form-control" id="Ref1Mobile" name="Ref1Mobile" value={referenceDetails.Mobile || ''} onChange={(event) => updateReferenceDetails('Mobile', event.target.value)}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

